@use '../../../styles/variables' as *;

.width {
  width: 500px !important;
}

.container {
  padding: 25px 20px;
  font-family: $font-family-base;

  h1 {
    margin-bottom: 20px;
    color: $eerie-black;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  .searchAndButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    height: 35px;

    .searchContainer {
      display: flex;
      align-items: center;
      width: 40%;
      border: 0.5px solid $gray;
      border-radius: 4px;
      padding: 0 10px;
      border-radius: 5px;
      background: $seasalt;
      
      
      &:focus-within {
        transition: 0.5s;
        border-color: $process-cyan;

        .searchIcon {
          transition: 0.5s;
          color: $process-cyan;
        }
      }
    }

    .searchIcon {
      margin-right: 10px;
    }

    .searchBar {
      flex: 1;
      padding: 10px;
      font-size: 14px;
      border: none;
      outline: none;
      background: $seasalt;
      height: 35px;
    }

    .addContentButton {
      font-size: 14px;
      color: $white;
      border-radius: 5px;
      background-color: $process-cyan;
      border: none;
      cursor: pointer;
      height: 35px;
      padding: 0 10px;
    }

    .addContentButton:hover {
      transition: 0.5s;
      background-color: $blue;
    }
  }

  .scrollToTopButton {
    position: fixed;
    bottom: 20px;
    right: 7px;
    background-color: $process-cyan;
    color: $white;
    border: none;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;


    &:hover {
      background-color: $blue;
    }

    &:focus {
      outline: none;
    }
  }
}

.confirmButton {
  color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
  background: $process-cyan;
}

.confirmButton:hover {
  transition: 0.5s;
  background-color: $blue;
  border-color: $blue;
}


.cancelButton {
  color: $process-cyan;
  background-color: $white;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $process-cyan;
}

.cancelButton:hover {
  transition: 0.5s;
  color: $white;
  background-color: $process-cyan;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $process-cyan;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid $process-cyan;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}


.tableContainer {
  font-family: $font-family-base;
  overflow-y: auto;
  max-height: calc(100vh - 220px);
  height: calc(100vh - 220px);

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray;
    border-radius: 6px;
    border: 3px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $dim-gray;
  }
  
  .contentTable {
    width: 100%;
    padding: 0px 20px;
    align-items: center;
    gap: 25px;

    th {
      color: $eerie-black;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      padding: 15px 25px;
      border-bottom: 1px solid $b-light;
      vertical-align: middle;

      &.sorting:hover {
        cursor: pointer;
      }
    }

    td {
      padding: 8px;
      font-family: $font-family-base;
      font-size: 14px;
      font-weight: 400;
      color: $eerie-black;
      padding: 15px 25px;
      border-bottom: 1px solid $b-light;
      vertical-align: middle;

      .tooltip {
        position: relative;
        display: inline-block;

        .tooltiptext {
          visibility: hidden;
          width: auto;
          max-width: 300px;
          background: $white;
          color: $process-cyan;
          text-align: left;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid $alice-blue;
          box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.10);
          position: absolute;
          z-index: 3;
          bottom: 125%;
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }

        &:hover .tooltiptext {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .contentInfo {
      display: flex;
      align-items: center;

      .imageContainer {
        position: relative;

        .contentImage {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          margin-right: 10px;
          object-fit: cover;
        }

        .playIcon {
          position: absolute;
          bottom: -2px;
          right: 5px;
          border-radius: 50%;
          color: $kelly-green;
          
        }
        .loadingIndicator {
          width: 100%;
          height: 100%;
          display: flex;
          color: $blue;
          margin-right: 10px;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .contentTitle {
        color: $dim-gray;
        font-size: 12px;
        font-weight: 400;
        line-height: 140%;
      }

      .contentDescription {
        color: $eerie-black;
        font-size: 14px;
        font-weight: 600;
        line-height: 140%;
      }
    }
  }
}

.link {
  color: $process-cyan;
  text-decoration: none;
}

.status {
  .statusGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-shrink: 0;
  }

  .statusText {
    padding: 3px 0px;
    border-radius: 2px;
    font-size: 10px;
    text-align: center;
    font-style: normal;
    vertical-align: middle;
    font-weight: 600;
    line-height: 140%;
    color: $white;
    width: 80px;
    height: 20px;

    .statusIcon {
      width: 16px;
      height: 16px;
      margin-right: 2px;
    }
  }

  &.active .statusText {
    background-color: $green-1;
  }

  &.inactive .statusText {
    background-color: $red;
  }

  &.live .statusText {
    background-color: $orange;
  }
}

.dropdownContainer {
  position: relative;
  text-align: right;
  min-width: 60px;

  .dropIcon {
    transform: rotate(-90deg);
    cursor: pointer;
  }
}

.centerColumn {
  text-align: center;
  justify-content: center;
}

.dropdownMenu {
  display: flex;
  width: 130px;
  height: 90px;
  padding: 20px 10px;
  position: absolute;
  bottom: 0px;
  top: 30px;
  right: 50%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 6px;
  z-index: 3;
  border-radius: 5px;
  border: 1px solid $alice-blue;
  background: $white;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.10);

  button {
    display: block;
    width: 100%;
    padding: 6px 10px;
    background: none;
    border-radius: 5px;
    border: none;
    text-align: left;
    cursor: pointer;

    .iconButton {
      margin-right: 10px;
    }

    &:hover {
      background: rgba($process-cyan, 0.12);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      animation-duration: 300ms;

      .iconButton {
        color: $process-cyan;
      }
    }
  }
}
.liveBackground {
  background: #e6f4ff80;
}