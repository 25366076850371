@use '../../../styles/variables' as *;

.width {
  width: 500px !important;
}

.container {
  padding: 25px 20px;
  font-family: $font-family-base;

  h1 {
    margin-bottom: 20px;
    color: $eerie-black;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  .searchAndButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    height: 35px;

    .searchContainer {
      display: flex;
      align-items: center;
      width: 40%;
      border: 0.5px solid $gray;
      border-radius: 4px;
      padding: 0 10px;
      border-radius: 5px;
      background: $seasalt;
      
      
      &:focus-within {
        transition: 0.5s;
        border-color: $process-cyan;

        .searchIcon {
          transition: 0.5s;
          color: $process-cyan;
        }
      }
    }

    .searchIcon {
      margin-right: 10px;
    }

    .searchBar {
      flex: 1;
      padding: 10px;
      font-size: 14px;
      border: none;
      outline: none;
      background: $seasalt;
      height: 35px;
    }

    .addUsersButton {
      font-size: 14px;
      color: $white;
      border-radius: 5px;
      background-color: $process-cyan;
      border: none;
      cursor: pointer;
      height: 35px;
      padding: 0 10px;
    }

    .addUsersButton:hover {
      transition: 0.5s;
      background-color: $blue;
    }
  }

  .scrollToTopButton {
    position: fixed;
    bottom: 20px;
    right: 7px;
    background-color: $process-cyan;
    color: $white;
    border: none;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;


    &:hover {
      background-color: $blue;
    }

    &:focus {
      outline: none;
    }
  }
}

.tableContainer {
  font-family: $font-family-base;
  overflow-y: auto;
  max-height: calc(100vh - 220px);
  height: calc(100vh - 220px);

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray;
    border-radius: 6px;
    border: 3px solid transparent;
    background-clip: users-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $dim-gray;
  }

  .usersTable {
    width: 100%;
    padding: 0px 20px;
    align-items: center;
    gap: 25px;

    th {
      color: $eerie-black;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      padding: 15px 25px;
      border-bottom: 1px solid $b-light;
      vertical-align: middle;

      &.sorting:hover {
        cursor: pointer;
      }
    }

    td {
      padding: 8px;
      font-family: $font-family-base;
      font-size: 14px;
      font-weight: 400;
      color: $eerie-black;
      padding: 15px 25px;
      border-bottom: 1px solid $b-light;
      vertical-align: middle;

      .tooltip {
        position: relative;
        display: inline-block;

        .tooltiptext {
          visibility: hidden;
          width: auto;
          max-width: 300px;
          background: $white;
          color: $process-cyan;
          text-align: left;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid $alice-blue;
          box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.10);
          position: absolute;
          z-index: 3;
          bottom: 125%;
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }

        &:hover .tooltiptext {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .usersInfo {
      display: flex;
      align-items: center;

      .imageContainer {
        position: relative;

      }

      .usersTitle {
        color: $dim-gray;
        font-size: 12px;
        font-weight: 400;
        line-height: 140%;
      }

      .usersDescription {
        color: $eerie-black;
        font-size: 14px;
        font-weight: 600;
        line-height: 140%;
      }
    }
  }
}