@use '../../../../styles/variables' as *;

.usersInfo {
  display: flex;
  align-items: center;

  .imageContainer {
    position: relative;

  }

  .usersTitle {
    color: $dim-gray;
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
  }

  .usersDescription {
    color: $eerie-black;
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
  }
}

td {
  padding: 8px;
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: 400;
  color: $eerie-black;
  padding: 15px 25px;
  border-bottom: 1px solid $b-light;
  vertical-align: middle;
}

th {
  color: $eerie-black;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  padding: 15px 25px;
  border-bottom: 1px solid $b-light;
  vertical-align: middle;

  &.sorting:hover {
    cursor: pointer;
  }
}

.tooltip {
  position: relative;
  display: inline-block;

  .tooltiptext {
    visibility: hidden;
    width: auto;
    max-width: 300px;
    background: $white;
    color: $process-cyan;
    text-align: left;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid $alice-blue;
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.10);
    position: absolute;
    z-index: 3;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}

.textEmail {
  color: $process-cyan !important;
  text-decoration: none;
}

.dropdownContainer {
  position: relative;
  text-align: right;

  .dropIcon {
    transform: rotate(-90deg);
    cursor: pointer;
  }
}

.centerColumn {
  text-align: center;
  justify-content: center;
}

.dropdownMenu {
  display: flex;
  width: 130px;
  height: 90px;
  padding: 20px 10px;
  position: absolute;
  bottom: 0px;
  top: 30px;
  right: 50%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 6px;
  z-index: 3;
  border-radius: 5px;
  border: 1px solid $alice-blue;
  background: $white;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.10);

  button {
    display: block;
    width: 100%;
    padding: 6px 10px;
    background: none;
    border-radius: 5px;
    border: none;
    text-align: left;
    cursor: pointer;

    .iconButton {
      margin-right: 10px;
    }

    &:hover {
      background: rgba($process-cyan, 0.12);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      animation-duration: 300ms;

      .iconButton {
        color: $process-cyan;
      }
    }
  }
}

.status {
  .statusGroup {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    margin-right: 0;
    gap: 10px;
  }

  .statusText {
    &.activeLabel{
      width: 95px;
    }

    &.adminLabel {
      width: 44px;
    }
    span{
      color: $eerie-black;
      font-size: 14px;
    }

  }

  .switch {
    margin-left: 10px;
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $gray;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: $white;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: $green-1;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $green-1;
  }

  input:checked + .slider:before {
    transform: translateX(14px);
  }

  .slider.round {
    border-radius: 20px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.viewAllButton {
  margin-left: 5px;
  color: $white;
  text-align: center;
  font-family: $font-family-base;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  background-color: $process-cyan;
  border-radius: 2px;
  border: none;
}
